import React, { useEffect } from 'react';
import PropTypes from "prop-types";
const Header = ({activeMenu}) => {

  useEffect(() => {
  }, []);

  return (
    <>
      {/* <div class="page_loader"></div> */}
      <header class="main-header abs-header " id="main-header-2">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <nav class="navbar navbar-expand-lg navbar-light rounded">
                <a class="navbar-brand" href="#" aria-label="Facebook">
                  <i class="fa fa-facebook"></i>
                </a>
                <a class="navbar-brand" href="#" aria-label="Linkedin">
                  <i class="fa fa-linkedin"></i>
                </a>
                <a class="navbar-brand" href="#" aria-label="Twitter">
                  <i class="fa fa-twitter"></i>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#topnavbar" aria-controls="navbar" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span class="fa fa-bars"></span>
                </button>
                <div class="collapse navbar-collapse" id="topnavbar">
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarResources"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  aria-label="Resources">
                        Resources
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarResources">
                        <li><a class="dropdown-item" href="/inventor-resources" aria-label="Inventor Resources">Inventor
                          Resources</a></li>
                        <li><a class="dropdown-item" href="/probono" aria-label="USPTO Pro Bono Program">USPTO Pro Bono Program</a></li>
                        <li><a class="dropdown-item" href="/patent-guide" aria-label="Patent Guide">Patent Guide</a></li>
                        <li><a class="dropdown-item" href="/press-releases" aria-label="Press Releases">Press Releases</a></li>
                      </ul>
                    </li>

                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" href="#" id="navbarPatent" role="button"
                        data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true"
                        aria-expanded="false" aria-label="Patent FAQs">
                        Patent FAQs
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarPatent">
                        <li><a class="dropdown-item" href="/patentfaqs" aria-label="Patent FAQs">Patent FAQs</a></li>
                        <li><a class="dropdown-item" href="/patentfaqs/ip-basics" aria-label="IP Basics">IP Basics</a></li>

                        <li class="dropdown-submenu">
                          <a class="dropdown-item dropdown-toggle" href="#" id="navbarPatentBasics"
                            role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" aria-label="Patent Basics">
                            Patent Basics
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="navbarPatentBasics">
                            <li><a class="dropdown-item" href="/patentfaqs/patent-basics" aria-label="Patent Basics">Patent
                              Basics</a></li>
                            <li><a class="dropdown-item"
                              href="/patentfaqs/set-up-an-account-with-uspto" aria-label="Set up an Account with the USPTO">Set up an
                              Account with the USPTO</a></li>
                            <li><a class="dropdown-item"
                              href="/patentfaqs/need-for-patent-attorney-or-agent">Need
                              for a Patent Attorney or Agent</a></li>
                          </ul>
                        </li>
                        <li class="dropdown-submenu">
                          <a class="nav-link dropdown-toggle" href="#" id="navbarPatentApplication"
                            role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" aria-label="Provisional Patent Application">
                            Provisional Patent Application
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="navbarPatentApplication">
                            <li><a class="dropdown-item"
                              href="/patentfaqs/provisional-patent-application" aria-label="Provisional Patent Application">Provisional
                              Patent Application</a></li>
                            <li><a class="dropdown-item"
                              href="/patentfaqs/provisional-builder" aria-label="Provisional Builder">Provisional
                              Builder</a></li>
                            <li><a class="dropdown-item"
                              href="/patentfaqs/after-submit-ppa" aria-label="After you Submit a
                              PPA">After you Submit a
                              PPA</a></li>
                          </ul>
                        </li>
                        <li class="dropdown-submenu">
                          <a class="nav-link dropdown-toggle" href="#"
                            id="navbarUtilityPatentApplication" role="button"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Utility Patent Application
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="navbarUtilityPatentApplication">
                            <li><a class="dropdown-item"
                              href="/patentfaqs/utility-patent-application">Utility Patent
                              Application</a></li>
                            <li><a class="dropdown-item"
                              href="/patentfaqs/file-a-utility-patent-application">File a
                              Utility Patent Application</a></li>
                            <li><a class="dropdown-item"
                              href="/patentfaqs/what-happens-after-filing-utility-application">What
                              Happens After Filing Utility Application?</a></li>
                          </ul>
                        </li>
                        <li><a class="dropdown-item" href="respond-to-office-action">Respond to
                          Office Actions</a></li>
                        <li><a class="dropdown-item" href="patent-issued">Patent Issued</a></li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link single" href="/blog">Blog</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link single" href="/join-us">Join Us</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-12">
              <nav class="navbar navbar-expand-lg navbar-light rounded">
                <a class="navbar-brand logo" href="/" aria-label='Logo'>
                  <img src="/assets/img/logos/logo.png" alt="logo" />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
                  aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="fa fa-bars"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbar">
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                      <a class={"nav-link single " + (!activeMenu || activeMenu === 'home' ? 'active' : '')} href="/">Home</a>
                    </li>
                    <li class={"nav-item dropdown"}>
                      <a class={"nav-link dropdown-toggle "  + (activeMenu === 'about' ? 'active' : '') } href="#" id="navbarDropdownMenuLink"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        About Us
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li><a class="dropdown-item" href="/#who-we-are">Who We Are</a></li>
                        <li><a class="dropdown-item" href="/what-we-do"  aria-label="What We Do">What We Do</a></li>
                        <li><a class="dropdown-item" href="/our-history">Our History</a></li>
                        <li><a class="dropdown-item" href="/#how-to-help">How to Help</a></li>
                        <li><a class="dropdown-item" href="/#where-we-work">Where We Work</a></li>
                        <li><a class="dropdown-item" href="/our-science-technology">Our Science &
                          Technology New</a></li>
                      </ul>
                    </li>

                    <li class={"nav-item dropdown"}>
                      <a class={"nav-link dropdown-toggle "  + (activeMenu === 'builder' ? 'active' : '') } href="#" id="navbarDropdownServicesMenuLink"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Services
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="navbarDropdownServicesMenuLink">
                        <li><a class="dropdown-item" href="/provisional-builder">Provisional Builder</a></li>
                        <li><a class="dropdown-item" href="/patent-strategy">Patent Strategy</a></li>
                        <li><a class="dropdown-item" href="https://canvas.inventiv.org/">Canvas Builder</a></li>
                      </ul>
                    </li>
                    {/* <li class="nav-item">
                      <a class={"nav-link single " + (activeMenu === 'builder' ? 'active' : '')} href="/provisional-builder">Provisional Builder</a>
                    </li>
                    <li class="nav-item">
                      <a class={"nav-link single " + (activeMenu === 'canvas' ? 'active' : '')} href="https://canvas.inventiv.org/">Canvas Builder</a>
                    </li> */}
                    <li class="nav-item">
                      <a class={"nav-link single " + (activeMenu === 'webinar' ? 'active' : '')} href="/webinar">Webinar</a>
                    </li>
                    <li class="nav-item">
                      <a class={"nav-link single " + (activeMenu === 'involved' ? 'active' : '')} href="/get-involved">Get Involved</a>
                    </li>
                    <li class="nav-item">
                      <a class={"nav-link single " + (activeMenu === 'contact' ? 'active' : '')} href="/contact">Contact</a>
                    </li>
                    {/* <li class="nav-item">
                      <a class="nav-link single" href="https://www.inventiv.org/docket/"><i
                        class="fa fa-lock"></i> Login</a>
                    </li> */}
                    <li class="nav-item">
                      <a class="nav-link donate-button" href="/donate">Donate</a>
                    </li>

                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  activeMenu: PropTypes.string
}

export default Header;
