/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import CookieConsent from './cookie-consent';
import { addExternalScript } from "../utils/utils";

const Layout = ({ children, activeMenu }) => {
  const checkAlreadyAcceptCookie = () => {
    // "document" is not available during server side rendering.
    // https://github.com/gatsbyjs/gatsby/issues/19487
    if (typeof window === 'undefined' || !window.document) {
      return undefined;
    }
    var match = document.cookie.match(new RegExp('(^| )accept_cookie=([^;]+)'));
    if (match) {
      return true;
    }
    else {
      return false;
    }
  }
  useEffect(() => {
    // openWebinarPopup();
    addExternalScript("/assets/js/gtm.js")
  }, []);
  return (
    <>
      <Header activeMenu={activeMenu}/>
      <main>{children}</main>
      <Footer />
      <CookieConsent accepted={checkAlreadyAcceptCookie()}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  activeMenu: PropTypes.string
}

export default Layout